<template>
    <div id="index" ref="appRef">
      <div class="bg">
        <!-- <dv-loading>Loading...</dv-loading> -->
        <img class="feack" src="../assets/img/fanhui.png" alt="" @click="back" />

        <div class="icon_time">
            <img src="../assets/img/ic_tb_time.png" alt="" />
            {{ dateYear }} {{dateWeek}} {{ dateDay }}
        </div>

        <div class="title">九赋生活指挥中心-产能中心</div>
        <img class="title_bj" src="../assets/img/title_bj.png" alt="" />


        <div class="massLeft">

            <!-- 今日拼团总额 -->
            <div class="newUser" style="background: #051b4a;">
                <dv-border-box-8 style=" width:616px; height:265px;">
                    <div class="dayGmv_title">
                        <img src="../assets/img/dayGmv_left.png" alt="" />
                        今日拼团总额
                        <img src="../assets/img/dayGmv_right.png" alt="" />
                    </div>

                    <div class="newPrice">
                        <div class="newPrice_left">
                            <div class="newPrice_left_title">
                                <p><span></span>本月新增</p>
                            </div>
                            <div class="unm">
                                <CountTo :startVal='startVal' :endVal='endVal2' :duration='duration' />
                            </div>
                        </div>
                        <!-- <div class="newPrice_left" style="border-right: none;">
                            <div class="newPrice_left_title">
                                <p style="color: #3467FF;"><span style="background-color: #3467FF;"></span>本月新增</p>
                            </div>
                            <div class="unm" style="color: #3467FF;">
                                <CountTo :startVal='startVal' :endVal='endVal3' :duration='duration' />
                            </div>
                        </div> -->
                    </div>


                    <div class="price">
                        <div class="num">
                            <CountTo :startVal='startVal' :endVal='endVal1' :duration='duration' />
                        </div>

                        <div class="day_num">
                            较昨日 <span :style="{color:endVal3*1 >= 0 ? '#FF0000':'#14FF39'}">{{endVal3}}%</span>
                            <img v-if="endVal3*1 >= 0 " src="../assets/img/dayGmv_top.png" alt="">
                            <img v-else src="../assets/img/dayGmv_botton.png" alt="">
                        </div>

                    </div>

                </dv-border-box-8>
            </div>
            <!-- 今日拼团总额 -->

            <div class="piecing">
                <div class="sphere_title">
                    <div class="titletext">
                        拼团金额统计
                    </div>
                    <div class="sphere_tabqiehuan">
                        <span @click="sphereClick1(1)" :class="sphere1 == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                        <span @click="sphereClick1(2)" :class="sphere1 == 2 ? 'on':''">本月</span>
                    </div>
                </div>
                <div class="sphere_con" id="main1"></div>
            </div>

            <!-- 拼团情况（近10天） -->

            <div class="sphere">
                <div class="sphere_title">
                    <div class="titletext" style="color: #fff;">
                        拼团情况（近10天）
                    </div>
                </div>
                <div class="sphere_conlist">
                    <div class="title_text">
                        <span style="width: 130px;">时间</span>
                        <span style="width: 152px;">活动名称</span>
                        <span style="width: 152px;">拼团人数</span>
                        <span style="width: 152px;">拼团总额</span>
                    </div>


                    <div class="massRight_con">
                        <div class="conlist" v-for="(item,index) in amountList" :key="index">
                            <div class="channel">
                                <div class="channel_left">{{ item.date }}</div>
                                <div class="channel_right">
                                    <div v-for="(items,indexs) in item.data" :key="indexs">
                                        <span>{{ items.title }}</span>
                                        <span>{{ items.count }}</span>
                                        <span>{{ items.sum }}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

            <!-- 拼团情况（近10天） -->

        </div>
        <!-- 左 -->
        <!-- 中 -->
        <div class="massCenter">

            <!-- 能量树 -->
            <div class="energyTree" v-if="energyTreeList">

                <!-- <block v-for="(item,index) in 20" :key="index"> -->
                    <!-- <div class="square" :style="{a:5,top:(Math.random() * 300)+'px',left:(Math.random() * 460)+'px',}">200</div> -->
                <!-- </block> -->
                
                <div class="square" style="--i:1">{{ energyTreeList[0].points }}</div>
                <div class="square" style="--i:2">{{ energyTreeList[1].points }}</div>
                <div class="square" style="--i:3">{{ energyTreeList[2].points }}</div>
                <div class="square" style="--i:4">{{ energyTreeList[3].points }}</div>
                <div class="square" style="--i:5">{{ energyTreeList[4].points }}</div>
                <div class="square" style="--i:6">{{ energyTreeList[5].points }}</div>
                <div class="square" style="--i:7">{{ energyTreeList[6].points }}</div>
                <div class="square" style="--i:8">{{ energyTreeList[7].points }}</div>
                <div class="square" style="--i:9">{{ energyTreeList[8].points }}</div>
                <!-- <div class="square" style="--i:10">123</div>
                <div class="square" style="--i:11">123</div>
                <div class="square" style="--i:12">123</div>
                <div class="square" style="--i:13">123</div>
                <div class="square" style="--i:14">123</div>
                <div class="square" style="--i:15">123</div> -->
                <!-- <div class="square" style="--i:16">123</div>
                <div class="square" style="--i:17">123</div>
                <div class="square" style="--i:18">123</div>
                <div class="square" style="--i:19">123</div>
                <div class="square" style="--i:20">123</div> -->
            </div>
            <!-- 能量树 -->
            <!-- 合成情况 -->
            <div class="synthesis">
                <div class="synthesis_title">合成情况</div>
                <div class="title_text">
                    <span style="width: 150px;">用户昵称</span>
                    <span style="width: 150px;">合成消耗能量</span>
                    <span style="width: 120px;">合成商品</span>
                    <span style="width: 145px;">时间</span>
                </div>
                <div class="synthesis_con">
                    <div class="conlist" v-for="(item,index) in siteList" :key="index">
                        <span style="width: 150px;">{{ item.phone }}</span>
                        <span style="width: 150px;">{{ item.points }}</span>
                        <span style="width: 120px; color: #24B2FD;">查看</span>
                        <span style="width: 145px;">{{ item.create_time }}</span>
                    </div>
                </div>
            </div>
            <!-- 合成情况 -->
        </div>
        
        <!-- 中 -->
        <!-- 右 -->

        <div class="massRight">
            
            <!-- 今日出货总额 -->
            <div class="todays">
                <dv-border-box-8 :reverse="true" style="width:617px; height:332px;">
                    <div class="todays_con">

                        <div class="todays_title">
                            <img src="../assets/img/dayGmv_left.png" alt="" />
                            今日出货总额
                            <img src="../assets/img/dayGmv_right.png" alt="" />
                        </div>

                        <div class="price">
                            <div class="num">
                                <CountTo :startVal='startVal' :endVal='gmvendVal1' :duration='duration' />
                            </div>

                            <div class="day_num">
                                较昨日 <span :style="{color:gmvendVal5*1 >= 0 ? '#FF0000':'#14FF39'}">{{gmvendVal5}}%</span>
                                <img v-if="gmvendVal5*1 >= 0 " src="../assets/img/dayGmv_top.png" alt="">
                                <img v-else src="../assets/img/dayGmv_botton.png" alt="">
                            </div>

                        </div>

                        <div class="newPrice_left">
                            <div class="newPrice_left_title">
                                <p><span></span>本月数量</p>
                            </div>
                            <div class="unm">
                                <CountTo :startVal='startVal' :endVal='gmvendVal2' :duration='duration' />
                            </div>
                        </div>
                        
                    </div>
                    <!-- 今日出货总额 -->
                    <div class="todays_con">

                        <div class="todays_title" style="margin-top: 0px;">
                            <img src="../assets/img/dayGmv_left.png" alt="" />
                            今日提货总额
                            <img src="../assets/img/dayGmv_right.png" alt="" />
                        </div>

                        <div class="price" style="border-bottom:none; margin-top: 60px;">
                            <div class="num">
                                <CountTo :startVal='startVal' :endVal='gmvendVal3' :duration='duration' />
                            </div>

                            <div class="day_num">
                                较昨日 <span :style="{color:gmvendVal6*1 >= 0 ? '#FF0000':'#14FF39'}">{{gmvendVal6}}%</span>
                                <img v-if="gmvendVal6*1 >= 0 " src="../assets/img/dayGmv_top.png" alt="">
                                <img v-else src="../assets/img/dayGmv_botton.png" alt="">
                            </div>

                        </div>

                        <div class="newPrice_left" style="position: absolute;top: 195px;">
                            <div class="newPrice_left_title">
                                <p><span></span>本月数量</p>
                            </div>
                            <div class="unm">
                                <CountTo :startVal='startVal' :endVal='gmvendVal4' :duration='duration' />
                            </div>
                        </div>

                    </div>
                    <!-- 今日提货总额 -->
                </dv-border-box-8>
            </div>
            <!-- 今日出货总额 -->

            <!-- 商品出货/提货金额统计 -->
            <div class="private">
                <div class="sphere_title">
                    <div class="titletext">
                        商品出货/提货金额统计
                    </div>
                    <div class="sphere_tabqiehuan">
                        <span @click="sphereClick2(1)" :class="sphere2 == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                        <span @click="sphereClick2(2)" :class="sphere2 == 2 ? 'on':''">本月</span>
                    </div>
                </div>
                <div class="sphere_con" id="main2"></div>
            </div>
            <!-- 商品出货/提货金额统计 -->

            <!-- 用户出货/提货排行TOP10 -->

            <div class="incubate_right">
              
                <div class="sphere_title">
                    <div class="titletext">
                        用户出货/提货排行TOP10
                    </div>
                    <div class="sphere_tabqiehuan">
                        <span @click="sphereClick3(1)" :class="sphere3 == 1 ? 'on':''" style="margin-right: 30px;">本周</span>
                        <span @click="sphereClick3(2)" :class="sphere3 == 2 ? 'on':''">本月</span>
                    </div>
                </div>

                <div class="topListOne">
                    <div class="topList">
                        <div class="topList_con" v-for="(item,index) in topList" :key="index">
                            <div class="img" v-if="index+1 == 1"><img src="../assets/img/zj_t1.png" alt=""></div>
                            <div class="img" v-else-if="index+1 == 2"><img src="../assets/img/zj_t2.png" alt=""></div>
                            <div class="img" v-else-if="index+1 == 3"><img src="../assets/img/zj_t3.png" alt=""></div>
                            <div class="text" v-else>
                                {{ index+1 }}
                            </div>

                            <div class="titles">
                                <div class="titles_zi">
                                    <span>{{ item.phone }}</span>
                                    <span style="color: #34DBFC; text-align: right;">{{ item.amount }}</span>
                                </div>
                                <div class="titles_jd">
                                    <div class="bj"></div>
                                    <div class="jianbian" :style="{width:((item.amount*1) / 100000)*100 + '%' }"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- 用户出货/提货排行TOP10 -->
        </div>

        <!-- 右 -->
      </div>
    </div>
  </template>
  
<script>
import { energyTree,pingAmountTotal,compositeList,pingAmountStatistics,pingAmountList,goodsAmoutTotal,goodsAmoutStatistics,userGoodsTopList } from '../config/httpApi';
import echarts from 'echarts';
import CountTo from 'vue-count-to'
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
  export default {
    components: {
        CountTo
    },
    mixins: [drawMixin],
    data() {
      return {
        timing: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
        startVal: 0,
        duration: 1000,//总渠道新增用户

        endVal1: 183129,//总渠道新增用户
        endVal2: 95456,//总渠道新增用户
        endVal3: 56421,//总渠道新增用户


        gmvendVal1:1554, //今日出货总额
        gmvendVal2:34545656, //本月数量
        gmvendVal3:5432651, //今日提货总额
        gmvendVal4:18946547,    //本月数量
        gmvendVal5:0,   //出货较昨日
        gmvendVal6:0,   //提货较昨日

        sphere1:'1', //拼团金额统计
        sphere2:'1', //私域流量渠道销售额统计
        sphere3:'1', //用户出货/提货排行TOP10

        amountList:[],  //拼团情况（近10天）数据源
        siteList:[],    //合成情况
        topList:[], //私域流量渠道销售额统计
        energyTreeList:null,  //能量树
      }
    },
    mounted() {
        this.timeFn();
        this.cancelLoading();
        let that = this;
        setInterval(function() {
        //发送AJAX请求的代码
            that.endVal1 = 0;//总渠道新增用户
            that.endVal2 = 0;//总渠道新增用户
            that.endVal3 = 0;//总渠道新增用户

            that.gmvendVal1 = 0; //总渠道GMV
            that.gmvendVal2 = 0; //总渠道GMV
            that.gmvendVal3 = 0; //总渠道GMV
            that.gmvendVal4 = 0;
            that.gmvendVal5 = 0;
            that.gmvendVal6 = 0;
            that.pingAmountTotal(); //今日拼团总额
            that.goodsAmoutTotal(); //出货-提货总额
        }, 6000); //600000毫秒 = 10分钟


        setInterval(function() {
        //发送AJAX请求的代码
            that.amountList = [];
            that.siteList = [];
            that.topList = []; //私域流量渠道销售额统计
            that.energyTreeList = null;  //能量树
            that.pingAmountStatistics();    //拼团金额统计
            that.pingAmountList();  //拼团情况（近10天）
            that.compositeList();   //合成情况
            that.goodsAmoutStatistics();   //私域流量渠道销售额统计
            that.userGoodsTopList();    //私域流量渠道销售额统计
            that.energyTree();  //能量树
        }, 600000); //600000毫秒 = 10分钟
        
        that.pingAmountStatistics();    //拼团金额统计
        that.pingAmountList();  //拼团情况（近10天）
        that.compositeList();   //合成情况
        that.goodsAmoutStatistics();   //私域流量渠道销售额统计
        that.userGoodsTopList();    //私域流量渠道销售额统计
        that.energyTree();  //能量树
        
    },
    beforeDestroy() {
      clearInterval(this.timing)
    },
    methods: {
        back(){
            this.$router.back();
        },
        energyTree(){  //能量树
            energyTree({}).then(res => {
                console.log(res)
                this.energyTreeList = res.energyData;
            })
        },
        pingAmountTotal(){ //今日拼团总额
            pingAmountTotal({}).then(res => {
                // console.log(res)
                this.endVal1 = res.pingAmountToday;//总渠道新增用户
                this.endVal2 = res.pingAmountMonth;//总渠道新增用户
                this.endVal3 = res.percent;//总渠道新增用户
            })
        },
        goodsAmoutTotal(){ //出货-提货总额
            goodsAmoutTotal({}).then(res => {
                // console.log(res)
                this.gmvendVal1 = res.outAmountToday; //今日出货总额
                this.gmvendVal2 = res.outAmountMoth; //本月数量
                this.gmvendVal3 = res.getAmountToday; //今日提货总额
                this.gmvendVal4 = res.getAmountMoth;    //本月数量
                this.gmvendVal5 = res.outPercent;   //出货较昨日
                this.gmvendVal6 = res.getPercent;   //提货较昨日
            })
        },
        compositeList(){    //合成情况
            compositeList({
                page:1,
                size:200
            }).then(res => {
                // console.log(res)
                this.siteList = res.data;
                // this.endVal1 = res.pingAmountToday;//总渠道新增用户
                // this.endVal2 = res.pingAmountMonth;//总渠道新增用户
                // this.endVal3 = res.percent;//总渠道新增用户
            })
        },
        pingAmountStatistics(){ //拼团金额统计
            pingAmountStatistics({type:this.sphere1}).then(res => {
                // console.log(res)
                //拼团金额统计
                const chart1 = echarts.init(document.getElementById('main1'));
                var option1;
                option1 = {
                    grid: { //设置折线图显示边界
                        left: '0%',
                        right: '0%',
                        bottom: '5%',
                        top:'4%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: res.xAxis,
                    },
                    yAxis: {
                        type: 'value',
                        splitLine:{
                            show:true,
                            lineStyle:{
                                type:'dashed',
                                color:'#fff',
                                opacity: '0.3'
                            }
                        }
                    },
                    series: [
                        {
                            data: res.series[0].data,
                            type: 'line',
                            lineStyle: {
                                color: '#55C994', // 设置线的颜色
                                width:3,//设置线条宽度
                                type:'solid'  //设置线条显示：'dotted'虚线 'solid'实线
                            },
                            itemStyle: {
                                normal: {
                                    color:'#fff', //折线点的颜色
                                    borderColor: '#ffffff', //拐点边框颜色
                                    borderWidth: 2 //拐点边框大小
                                },
                            },
                        }
                    ],
                    textStyle:{
                        fontSize: '26',
                        color: '#fff'
                    }
                };
                chart1.setOption(option1);
                //拼团金额统计
            })
        },
        pingAmountList(){   //拼团情况（近10天）
            pingAmountList({}).then(res => {
                // console.log(res)
                this.amountList = res;
            })
        },
        goodsAmoutStatistics(){   //私域流量渠道销售额统计
            goodsAmoutStatistics({type:this.sphere2}).then(res => {
                // console.log(res)
                //私域流量渠道销售额统计
                const chart2 = echarts.init(document.getElementById('main2'));
                var option2;
                option2 = {
                    tooltip: {
                        trigger: 'axis',
                        position: ['50%', '50%']// position: ['10px', '10px']
                    },
                    legend: {
                        data: res.legend,
                        bottom:'1%',
                        textStyle:{ 
                            color:'rgba(255,255,255,0.8)'
                        }
                    },
                    grid: { //设置折线图显示边界
                        left: '0%',
                        right: '0%',
                        bottom: '20%',
                        top:'3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: res.xAxis,
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine:{
                            show:true,
                            lineStyle:{
                                type:'dashed',
                                color:'#fff',
                                opacity: '0.3'
                            }
                        }
                        },
                        {
                            type: 'value',
                            splitLine:{
                            show:true,
                            lineStyle:{
                                type:'dashed',
                                color:'#fff',
                                opacity: '0.3'
                            }
                        }
                        }
                    ],
                    series: [
                        {
                            name: res.series[0].name,
                            type: 'bar',
                            color:new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(43, 247, 224, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(75, 135, 254, 1)'
                                }
                            ]), //折线点的颜色,,
                            data: res.series[0].data
                        },
                        {
                            name: res.series[1].name,
                            color:new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(255, 123, 149, 1)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(250, 63, 100, 1)'
                                }
                            ]), //折线点的颜色,
                            type: 'bar',
                            data: res.series[1].data
                        }
                    ],
                    textStyle:{
                        fontSize: '14',
                        color: '#fff'
                    }
                    };
                chart2.setOption(option2);
                // 渠道销售额统计
            })
        },
        userGoodsTopList(){ //私域流量渠道销售额统计
            userGoodsTopList({type:this.sphere3}).then(res => {
                // console.log(res)
                this.topList = res;
            })
        },
        sphereClick1(type){//拼团金额统计
            this.sphere1 = type;
            this.pingAmountStatistics();
        },
        sphereClick2(type){//私域流量渠道销售额统计 
            this.sphere2 = type;
            this.goodsAmoutStatistics();
        },
        sphereClick3(type){//用户出货/提货排行TOP10 
            this.sphere3 = type;
            this.userGoodsTopList();
        },
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        }
    }
  }
  </script>

<style lang="scss">
    ::-webkit-scrollbar{background-color: #000;width: 4px;}
    ::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
        width: 2px;
    } 
    ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.1);
    } 
    ::-webkit-scrollbar-button {
        background-color: rgba(255, 255, 255, 0.1);
    } 
    ::-webkit-scrollbar-corner {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .massRight_con::-webkit-scrollbar-thumb {
        border-radius: 10px;
        width: 2px;
        box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.4);
    }
    //鼠标移入样式
    .massRight_con::-webkit-scrollbar-thumb:hover {
        background: #333333;
    }
</style>
<style lang="scss" scoped>
@import '../assets/scss/index.scss';

// @import '../assets/scss/style.scss';
.bg{
    position: relative;
    .feack{
            width: 27px;
            height: 25px;
            position: absolute;
            top: 16px;
            left: 20px;
            cursor: pointer;
        }
    .icon_time{
        position: absolute;
        top: 19px; 
        right: 30px;
        
        img{
            width: 18rpx;
            height: 18px;
            margin-right: 5px;
            float: left;
        }
        font-size: 18px;
        font-weight: bold;
    }
    .title{
        width: 683px;
        height: 42px;
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 42px;
        background: url("../assets/img/top_bj.png") no-repeat center;
        background-size: 683px 24px;
        margin: 0 auto 0;
        text-align: center;
    }
    .title_bj{
        width: 1920px;
        height: 27px;

    }

    // 左
    .massLeft{
        width: 616px;
        height: auto;
        float: left;
        margin: 16px 0 0 20px;
        // 总渠道新增用户
        .newUser{
            width:616px;
            height: 265px;
            float: left;
            margin: 10px 0 0 0px;
            .dayGmv_title{
                width: auto;
                height: 28px;
                font-size: 20px;
                font-weight: 500;
                color: #34DBFC;
                line-height: 28px;
                margin: 0px 0 0 59px;
                padding-top: 63px;
            }
            .price{
                width: 616px;
                height: 55px;
                margin: 25px auto 0;
                font-size: 32px;
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                }
                .num{
                    width: auto;
                    height: 55px;
                    // margin-right: 10px;
                    font-size: 34px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 55px;
                    display: inline-block;
                }
                .day_num{
                    display: inline-block;
                    font-size: 24px;
                    height: 55px;
                    line-height: 55px;
                    // margin-top: 25px;
                    span{
                        color: #FF0000;
                    }
                    img{
                        width: 17px;
                        height: 18px;
                        margin-left: 5px;
                    }
                }
            }

            .newPrice{
                // border-top: 2px solid rgba(24,122,205,0.88);
                margin: -8px auto 0;
                text-align: right;
                width: 374px;
                .newPrice_left{
                    // width: 186px;
                    height: 80px;
                    display: inline-block;
                    text-align: center;
                    // border-right: 2px solid rgba(24,122,205,0.88);
                    .newPrice_left_title{
                        padding-top: 14px;
                        p{
                            height: 22px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #6633CC;
                            line-height: 22px;
                            span{
                                width: 19px;
                                height: 10px;
                                background: #6633CC;
                                border-radius: 5px;
                                display: inline-block;
                                margin-right: 6px;
                            }
                        }
                    }
                    .unm{
                        height: 28px;
                        font-size: 20px;
                        font-weight: 500;
                        color: #6633CC;
                        line-height: 28px;
                    }
                }
            }
        }
        // 总渠道新增用户

        // 拼团金额统计

        .piecing{
            width: 616px;
            height: 288px;
            background: url('../assets/img/piecing_bj.png') no-repeat;
            background-size: 616px 288px;
            float: left;
            margin: 20px 0 0 0px;
            .sphere_title{
                width: 616px;
                height: 26px;
                overflow: hidden;
                margin-top: 13px;
                .titletext{
                    width: 198px;
                    height: 26px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #F7A830;
                    line-height: 26px;
                    margin-left: 12px;
                    float: left;
                }
                .sphere_tabqiehuan{
                    width: 151px;
                    height: 26px;
                    background: url("../assets/img/ic_zxjg_tab_n.png") no-repeat center;
                    background-size: 151px 26px;
                    text-align: center;
                    float: right;
                    margin-right: 12px;
                    span{
                        width: 30px;
                        height: 26px;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(255,255,255,0.8);
                        line-height: 26px;
                        text-align: center;
                        display: inline-block;
                        cursor:pointer;
                    }
                    .on{
                        color: #34DBFC;
                        border-bottom: 1px solid #34DBFC;
                        padding-bottom: 4px;
                    }
                }
            }
            .sphere_con{
                width: 584px;
                height: 230px;
                float: left;
                margin: 13px 0 0 15px;
                // background: #F7A830;
                // opacity: .3;
            }
        }


        // 拼团金额统计

        .sphere{
            width: 616px;
            height: 345px;
            background: url('../assets/img/ptqk_bj.png') no-repeat;
            background-size: 616px 345px;
            float: left;
            margin: 20px 0 0 0px;
            .sphere_title{
                width: 596px;
                height: 26px;
                overflow: hidden;
                margin-top: 13px;
                .titletext{
                    width: 198px;
                    height: 26px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #F7A830;
                    line-height: 26px;
                    margin-left: 12px;
                    float: left;
                }
            }
           
            .sphere_conlist{
                .title_text{
                    width: 590px;
                    height: 33px;
                    background: #004091;
                    margin: 12px 12px 0;
                    display: flex;
                    line-height: 33px;
                    span{
                        flex:0 0 auto;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }


                .massRight_con{
                    width: 596px;
                    height: 250px;
                    overflow-y: auto;
                    margin: 0 13px;
                    .conlist{
                        height: 104px;
                        width: 590px;
                        .channel{
                            height: 104px;
                            overflow: hidden;
                            background: #003070;
                            margin-top: 12px;
                            .channel_left{
                                width: 130px;
                                float: left;
                                font-size: 15px;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 110px;
                                text-align: center;
                                float: left;
                                height: 110px;
                                background: #004091;
                            }
                            .channel_right{
                                float: left;
                                div{
                                    span{
                                        display: inline-block;
                                        text-align: center;
                                        height: 36px;
                                        width: 152px;
                                        line-height: 36px;
                                        border-bottom: 1px solid #004091;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // 私域流量渠道销售额统计


    }

    // 左

    // 中
    .massCenter{
        width: 588px;
        height: auto;
        overflow: hidden;
        float: left;
        margin: 28px 0 0 30px;

        // 能量树
        .energyTree{
            width: 588px;
            height: 580px;
            background: url('../assets/img/energyTree_bj.png') no-repeat center bottom;
            background-size: 588px 580px; 
            position: relative;
            // background: #f60;
            // opacity: .3;
            .square{
                position:absolute;
                width: 67px;
                height: 67px;
                background: rgba(255, 255, 255, .1);
                backdrop-filter: blur(5px);
                box-shadow: inset 0px 1px 13px 3px rgba(106,128,208,0.63);
                // border:1px solid rgba(255, 255, 255, .5);
                // border-right: 1px solid rgba(255, 255, 255, .2);
                // border-bottom: 1px solid rgba(255, 255, 255, .2);
                border-radius: 67px;
                animation: animate 5s linear infinite;
                animation-delay: calc(-1s*var(--i));
                text-align: center;
                line-height: 67px;
                color: #34DBFC;
                cursor: pointer;
            }
            @keyframes animate {
            0%,100%{
                transform: translateY(-20px);
            }
            50%{
                transform: translateY(20px);
            }
            }
            .square:nth-child(1){
                top: 54px;
                left: 44px;
            }
            .square:nth-child(2){
                top: 188px;
                left: 34px;
            }
            .square:nth-child(3){
                top: 30px;
                left: 200px;
            }
            .square:nth-child(4){
                top: 154px;
                left: 184px;
            }
            .square:nth-child(5){
                top: 54px;
                left: 295px;
            }
            .square:nth-child(6){
                top: 37px;
                right: 20px;
            }
            .square:nth-child(7){
                top: 156px;
                left: 350px;
            }
            .square:nth-child(8){
                top: 178px;
                right: 47px;
            }

            
            .square:nth-child(9){
                top: 132px;
                left: 110px;
            }
            .square:nth-child(10){
                top: 223px;
                left: 160px;
            }
            .square:nth-child(11){
                top: 200px;
                left: 60px;
            }
            .square:nth-child(12){
                top: 20px;
                left: 60px;
            }
            .square:nth-child(13){
                top: 176px;
                right: 26px;
            }
            .square:nth-child(14){
                top: 200px;
                right: 10px;
            }
            .square:nth-child(15){
                top: 200px;
                right: 139px;
            }
            .square:nth-child(16){
                top: 50px;
                right: 250px;
            }
            .square:nth-child(17){
                top: 178px;
                right: 205px;
            }
            .square:nth-child(18){
                top: 135px;
                right: 160px;
            }
            .square:nth-child(19){
                top: 258px;
                right: 123px;
            }
            .square:nth-child(20){
                top: 300px;
                right: 200px;
            }
        }
        // 能量树

        .synthesis{
            width: 588px;
            height: 345px;
            background: url('../assets/img/synthesis_bj.png') no-repeat;
            background-size: 588px 345px;
            float: right;
            margin-top: 11px;
            .synthesis_title{
                margin: 13px 12px 0;
                width: 108px;
                height: 25px;
                font-size: 18px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 25px;
            }
            .title_text{
                width: 566px;
                height: 33px;
                background: #004091;
                margin: 12px 12px 0;
                display: flex;
                line-height: 33px;
                span{
                    flex:0 0 auto;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                }
            }
            .synthesis_con{
                width: 572px;
                height: 238px;
                // background: #f60;
                margin: 12px 12px 0;
                overflow-y: auto;
                .conlist{
                    display: flex;
                    width: 566px;
                    height: 33px;
                    background: #003070;
                    margin-bottom: 12px;
                    span{
                        flex:0 0 auto;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                        height: 33px;
                        line-height: 33px;
                        color: rgba(255,255,255,0.7);
                    }
                }
                .conlist:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    
    // 中
    // 右
    .massRight{
        width: 617px;
        overflow: hidden;
        float: right;
        margin: 28px 20px 0 0;
        position: relative;
        .todays{
            width:617px;
            height:332px;
            background: #051b4a;
            .todays_title{
                width: auto;
                height: 28px;
                font-size: 20px;
                font-weight: 500;
                color: #34DBFC;
                line-height: 28px;
                margin: 0px 0 0 59px;
                padding-top: 34px;
            }
            .price{
                width: 464px;
                height: 65px;
                margin: 70px auto 0;
                font-size: 32px;
                display: flex;
                border-bottom: 2px solid rgba(24,122,205,0.88);;
                div{
                    flex:1;
                    // text-align: center;
                }
                .num{
                    width: auto;
                    height: 45px;
                    margin-right: 10px;
                    font-size: 34px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 45px;
                    display: inline-block;
                }
                .day_num{
                    display: inline-block;
                    font-size: 24px;
                    height: 45px;
                    margin-top: 15px;
                    text-align: right;
                    span{
                        color: #FF0000;
                    }
                    img{
                        width: 17px;
                        height: 18px;
                        margin-left: 5px;
                    }
                }
            }

            .newPrice_left{
                width: 186px;
                height: 80px;
                display: inline-block;
                text-align: right;
                position: absolute;
                top: 24px;
                right: 62px;
                .newPrice_left_title{
                    padding-top: 14px;
                    p{
                        height: 22px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #6633CC;
                        line-height: 22px;
                        span{
                            width: 19px;
                            height: 10px;
                            background: #6633CC;
                            border-radius: 5px;
                            display: inline-block;
                            margin-right: 6px;
                        }
                    }
                }
                .unm{
                    height: 28px;
                    font-size: 20px;
                    font-weight: 500;
                    color: #6633CC;
                    line-height: 28px;
                }
            }
        }
        // 今日出货总额
        .private{
            width: 617px;
            height: 301px;
            background: url('../assets/img/private_bj.png') no-repeat;
            background-size: 617px 301px;
            float: right;
            margin-top: 15px;
            .sphere_title{
                width: 617px;
                height: 26px;
                overflow: hidden;
                margin-top: 13px;
                .titletext{
                    width: 198px;
                    height: 26px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #F7A830;
                    line-height: 26px;
                    margin-left: 12px;
                    float: left;
                }
                .sphere_tabqiehuan{
                    width: 151px;
                    height: 26px;
                    background: url("../assets/img/ic_zxjg_tab_n.png") no-repeat center;
                    background-size: 151px 26px;
                    text-align: center;
                    float: right;
                    margin-right: 12px;
                    span{
                        width: 30px;
                        height: 26px;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(255,255,255,0.8);
                        line-height: 26px;
                        text-align: center;
                        display: inline-block;
                        cursor:pointer;
                    }
                    .on{
                        color: #34DBFC;
                        border-bottom: 1px solid #34DBFC;
                        padding-bottom: 4px;
                    }
                }
            }
            .sphere_con{
                width: 586px;
                height: 245px;
                float: left;
                margin: 13px 0 0 15px;
            }
        }
        // 私域流量渠道销售额统计

        .incubate_right{
            width: 617px;
            height: 273px;
            background: url('../assets/img/user_bj.png') no-repeat;
            background-size: 617px 273px;
            float: left;
            margin: 15px 0 0 0;

            .sphere_title{
                width: 617px;
                height: 26px;
                overflow: hidden;
                margin-top: 13px;
                .titletext{
                    width: 358px;
                    height: 26px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #34DBFC;
                    line-height: 26px;
                    margin-left: 12px;
                    float: left;
                }
                .sphere_tabqiehuan{
                    width: 151px;
                    height: 26px;
                    background: url("../assets/img/ic_zxjg_tab_n.png") no-repeat center;
                    background-size: 151px 26px;
                    text-align: center;
                    float: right;
                    margin-right: 12px;
                    span{
                        width: 30px;
                        height: 26px;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(255,255,255,0.8);
                        line-height: 26px;
                        text-align: center;
                        display: inline-block;
                        cursor:pointer;
                    }
                    .on{
                        color: #34DBFC;
                        border-bottom: 1px solid #34DBFC;
                        padding-bottom: 4px;
                    }
                }
            }
            .topListOne{
                width: 617px;
                overflow: hidden;
                .topList{
                    width: 600px;
                    margin: 16px 20px 0 10px;
                    height: 206px;
                    overflow-y: auto;
                    .topList_con{
                        margin-bottom: 12px;
                        height: 42px;
                        overflow: hidden;
                        .img{
                            width: 42px;
                            height: 42px;
                            float: left;
                            img{
                                width: 42px;
                                height: 42px;
                            }
                        }
                        .text{
                            width: 42px;
                            height: 42px;
                            background: #253978;
                            display: inline-block;
                            font-size: 20px;
                            font-weight: 500;
                            color: #FFFFFF;
                            line-height: 44px;
                            text-align: center;
                            float: left;
                        }

                        .titles{
                            width: 540px;
                            height: 42px;
                            float: left;
                            margin-left: 6px;
                            .titles_zi{
                                height: 25px;
                                display: flex;
                                span{
                                    flex: 1;
                                    font-size: 18px;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    line-height: 25px;
                                    margin-top: 6px;
                                }
                            }
                            .titles_jd{
                                width: 540px;
                                height: 16px;
                                margin-top: 8px;
                                position: relative;
                                .bj{
                                    width: 540px;
                                    height: 16px;
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;
                                    background: rgba(255,255,255,0.2);
                                }
                                .jianbian{
                                    width: 300px;
                                    height: 16px;
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;
                                    background: linear-gradient(270deg, #09B2FB 0%, #2036E7 100%);
                                }
                            }
                        }
                    }
                }
            }
        }
        // 用户出货/提货排行TOP10
    }
    // 右
}
</style>
  